import React, {useEffect, useState} from "react";
import {VerticalNavbar, setActiveValueInNavItems, NavItem} from "@nike/eds";
import {useLocation, useNavigate} from "react-router-dom";
import {URL_CUPS_OVERVIEW, URL_LOCATIONS, URL_PRINTERS, URL_TOP} from "./Constants";

export function SideBar(props) {

    const MENU_ID_CUPS_OVERVIEW = "CUPS_OVERVIEW"
    const MENU_ID_PRINTERS = "PRINTERS"
    const MENU_ID_LOCATIONS = "PRINT_STATIONS"

    const location = useLocation();

    const [items, setItems] = useState<Array<NavItem>>([
        {
            id: MENU_ID_PRINTERS,
            icon: "Print",
            label: "Printers",
            active: getCurrentActiveId() === MENU_ID_PRINTERS,
        },
        {
            id: MENU_ID_LOCATIONS,
            icon: "LocationPin",
            label: "Locations",
            active: getCurrentActiveId() === MENU_ID_LOCATIONS,
        },
        {
            id: MENU_ID_CUPS_OVERVIEW,
            icon: "Feed",
            label: "Cups overview",
            active: getCurrentActiveId() === MENU_ID_CUPS_OVERVIEW,
        },
    ]);

    function getCurrentActiveId(): string | undefined {
        if (location.pathname.startsWith(URL_PRINTERS) || location.pathname === URL_TOP) {// Printer overview is default page){
            return MENU_ID_PRINTERS;
        } else if (location.pathname.startsWith(URL_LOCATIONS)) {
            return MENU_ID_LOCATIONS;
        } else if (location.pathname.startsWith(URL_CUPS_OVERVIEW)) {
            return MENU_ID_CUPS_OVERVIEW;
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        const activeId: string | undefined = getCurrentActiveId();
        if (activeId) {
            const newItems = setActiveValueInNavItems(activeId, items);
            if (newItems) {
                setItems(newItems);
            }
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleClick = item => {
        const newItems: Array<NavItem> | undefined = setActiveValueInNavItems(item.id, items);
        if (newItems) {
            setItems(newItems);
        }
        switch (item.id) {
            case MENU_ID_CUPS_OVERVIEW: {
                navigate(URL_CUPS_OVERVIEW)
                break;
            }
            case MENU_ID_PRINTERS: {
                navigate(URL_PRINTERS)
                break;
            }
            case MENU_ID_LOCATIONS: {
                navigate(URL_LOCATIONS)
                break;
            }
        }
    };

    return (
        <VerticalNavbar id="sideBar"
                        navLogoSlot={null}// Default is the Nike logo which is already in the header
                        appName="Printer management"
                        appSubtitle=""
                        onNavigate={handleClick}
                        items={items}
        />
    )
}