import "@nike/eds/dist/index.css";
import React, {useContext} from "react";
import {Navigate, Route, Routes} from 'react-router-dom'
import {CupsJobDetail} from "./components/cups-jobs/detail/CupsJobDetail";
import {CupsJobSearchOverview} from "./components/cups-jobs/search/CupsJobSearchOverview";
import {AppsyncService} from "./components/shared/AppsyncService";
import {oktaAuth} from './components/config/OktaConfig'
import {CupsJobService} from "./components/shared/CupsJobService";
import {ApplicationSnackbar, SnackbarContext} from "node.glds-react-component-library";
import {PrinterSearchOverview} from "./components/printers/search/PrinterSearchOverview";
import {PrinterDetail} from "./components/printers/detail/PrinterDetail";
import {SideBar} from "./components/shared/SideBar";
import {PrinterService} from "./components/shared/PrinterService";
import {LocationSearchOverview} from "./components/locations/search/LocationSearchOverview";
import {LocationDetail} from "./components/locations/detail/LocationDetail";


const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const cupsJobService: CupsJobService = new CupsJobService(appsyncService)
const printerService: PrinterService = new PrinterService(oktaAuth)

export function App() {
    const snackbarCtx = useContext(SnackbarContext);
    return (
        <div className="fillWidth fillHeight eds-flex eds-flex--direction-row">
            <SideBar/>
            <div className="fillWidth fillHeight" style={{overflowX: "hidden", overflowY: "scroll"}}>
                <div className="mainContentLayout">
                    <Routes>
                        <Route path="/printer-management" element={<Navigate to={"printers"}/>}/>
                        <Route path="/printer-management/cups-job-overview" element={<CupsJobSearchOverview cupsJobService={cupsJobService}/>}/>
                        <Route path="/printer-management/cups-job-overview/logging/:hashKey" element={<CupsJobDetail cupsJobService={cupsJobService}/>}/>
                        <Route path="/printer-management/printers" element={<PrinterSearchOverview printerService={printerService}/>}/>
                        <Route path="/printer-management/printers/:node/:printerName?" element={<PrinterDetail printerService={printerService}/>}/>
                        <Route path="/printer-management/locations" element={<LocationSearchOverview printerService={printerService}/>}/>
                        <Route path="/printer-management/locations/:node/:locationName?" element={<LocationDetail printerService={printerService}/>}/>
                    </Routes>
                    {snackbarCtx.isDisplayed && <ApplicationSnackbar/>}
                </div>
            </div>
        </div>
    );
}

export default App;
