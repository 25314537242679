/* tslint:disable */
/* eslint-disable */
/**
 * Printer Determination
 * This is the API that is exposed for managing printer configuration in CUPS.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ErrorMessages
 */
export interface ErrorMessages {
    /**
     * 
     * @type {Array<ErrorMessage>}
     * @memberof ErrorMessages
     */
    'messages'?: Array<ErrorMessage>;
}
/**
 * 
 * @export
 * @interface LocationConfigurationDto
 */
export interface LocationConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationConfigurationDto
     */
    'node': string;
    /**
     * 
     * @type {string}
     * @memberof LocationConfigurationDto
     */
    'locationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationConfigurationDto
     */
    'locationName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationConfigurationDto
     */
    'printers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PrinterConfigurationDto
 */
export interface PrinterConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'node': string;
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'printerName': string;
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrinterConfigurationDto
     */
    'makeModel'?: string;
    /**
     * 
     * @type {Array<TrayDto>}
     * @memberof PrinterConfigurationDto
     */
    'trays'?: Array<TrayDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrinterConfigurationDto
     */
    'locations'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PrinterDto
 */
export interface PrinterDto {
    /**
     * 
     * @type {string}
     * @memberof PrinterDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PrinterModelsInner
 */
export interface PrinterModelsInner {
    /**
     * 
     * @type {string}
     * @memberof PrinterModelsInner
     */
    'modelName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrinterModelsInner
     */
    'multiTray'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrinterModelsInner
     */
    'prefix'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrinterModelsInner
     */
    'sizes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TrayDto
 */
export interface TrayDto {
    /**
     * 
     * @type {string}
     * @memberof TrayDto
     */
    'size'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LocationConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (body: LocationConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createLocation', 'body', body)
            const localVarPath = `/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrinter: async (body: PrinterConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createPrinter', 'body', body)
            const localVarPath = `/v1/printers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByPrinter: async (node: string, printer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('deleteByPrinter', 'node', node)
            // verify required parameter 'printer' is not null or undefined
            assertParamExists('deleteByPrinter', 'printer', printer)
            const localVarPath = `/v1/printers/{node}/{printer}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)))
                .replace(`{${"printer"}}`, encodeURIComponent(String(printer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location unique identifier of configuration to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (node: string, location: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('deleteLocation', 'node', node)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('deleteLocation', 'location', location)
            const localVarPath = `/v1/locations/{node}/{location}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)))
                .replace(`{${"location"}}`, encodeURIComponent(String(location)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLocations: async (node: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('findAllLocations', 'node', node)
            const localVarPath = `/v1/locations/{node}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPrinters: async (node: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('findAllPrinters', 'node', node)
            const localVarPath = `/v1/printers/{node}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location printstation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLocation: async (node: string, location: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('findByLocation', 'node', node)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('findByLocation', 'location', location)
            const localVarPath = `/v1/locations/{node}/{location}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)))
                .replace(`{${"location"}}`, encodeURIComponent(String(location)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPrinter: async (node: string, printer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'node' is not null or undefined
            assertParamExists('findByPrinter', 'node', node)
            // verify required parameter 'printer' is not null or undefined
            assertParamExists('findByPrinter', 'printer', printer)
            const localVarPath = `/v1/printers/{node}/{printer}`
                .replace(`{${"node"}}`, encodeURIComponent(String(node)))
                .replace(`{${"printer"}}`, encodeURIComponent(String(printer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterModel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/printermodel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (body: LocationConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateLocation', 'body', body)
            const localVarPath = `/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrinter: async (body: PrinterConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePrinter', 'body', body)
            const localVarPath = `/v1/printers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LocationConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(body: LocationConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrinter(body: PrinterConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrinter(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByPrinter(node: string, printer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByPrinter(node, printer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location unique identifier of configuration to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(node: string, location: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(node, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLocations(node: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationConfigurationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLocations(node, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPrinters(node: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrinterConfigurationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllPrinters(node, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location printstation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByLocation(node: string, location: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByLocation(node, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPrinter(node: string, printer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrinterConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByPrinter(node, printer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrinterModel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrinterModelsInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrinterModel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(body: LocationConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrinter(body: PrinterConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrinter(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {LocationConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(body: LocationConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.createLocation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrinter(body: PrinterConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.createPrinter(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByPrinter(node: string, printer: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteByPrinter(node, printer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location unique identifier of configuration to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(node: string, location: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocation(node, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLocations(node: string, options?: any): AxiosPromise<Array<LocationConfigurationDto>> {
            return localVarFp.findAllLocations(node, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPrinters(node: string, options?: any): AxiosPromise<Array<PrinterConfigurationDto>> {
            return localVarFp.findAllPrinters(node, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {string} location printstation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLocation(node: string, location: string, options?: any): AxiosPromise<LocationConfigurationDto> {
            return localVarFp.findByLocation(node, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} node node
         * @param {string} printer printer name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPrinter(node: string, printer: string, options?: any): AxiosPromise<PrinterConfigurationDto> {
            return localVarFp.findByPrinter(node, printer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterModel(options?: any): AxiosPromise<Array<PrinterModelsInner>> {
            return localVarFp.getPrinterModel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(body: LocationConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateLocation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrinterConfigurationDto} body Congiguration object that needs to be added persisted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrinter(body: PrinterConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.updatePrinter(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {LocationConfigurationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLocation(body: LocationConfigurationDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createLocation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrinterConfigurationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPrinter(body: PrinterConfigurationDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPrinter(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {string} printer printer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteByPrinter(node: string, printer: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteByPrinter(node, printer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {string} location unique identifier of configuration to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLocation(node: string, location: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteLocation(node, location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findAllLocations(node: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findAllLocations(node, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findAllPrinters(node: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findAllPrinters(node, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {string} location printstation name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findByLocation(node: string, location: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findByLocation(node, location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} node node
     * @param {string} printer printer name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findByPrinter(node: string, printer: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).findByPrinter(node, printer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPrinterModel(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPrinterModel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationConfigurationDto} body Congiguration object that needs to be added persisted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLocation(body: LocationConfigurationDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateLocation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrinterConfigurationDto} body Congiguration object that needs to be added persisted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePrinter(body: PrinterConfigurationDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePrinter(body, options).then((request) => request(this.axios, this.basePath));
    }
}


