import React from "react";
import {Table, TableHeading, TableCell, Skeleton} from "@nike/eds";
import {CupsJobSearchResultItem} from "./CupsJobSearchResultItem";

function CupsJobSearchResults(props) {

    const headers = [
        {Header: "Start timestamp"},
        {Header: "Job id"},
        {Header: "Source"},
        {Header: "Printer"},
        {Header: "Status"},
        {Header: "Cups instance"},
        {Header: "Logging"}
    ];

    return (
        <Table style={{wordBreak: "break-word"}}>
            <thead>
            <tr>
                {headers.map(column => (<TableHeading>{column.Header}</TableHeading>))}
            </tr>
            </thead>
            <tbody>
            {props.loading &&
                <>
                    <tr>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                    </tr>
                </>
            }
            {props.searchResults.map(searchResult => (
                <CupsJobSearchResultItem searchResult={searchResult}/>
            ))}
            </tbody>
        </Table>
    );
}

export {CupsJobSearchResults};
