import React, {useEffect} from "react";
import {useState} from "react";
import {Card, Tooltip} from "@nike/eds";
import {TextField} from "@nike/eds";
import {IconButton} from "@nike/eds";
import {Select} from "@nike/eds";
import {useQueryParam, StringParam, withDefault} from 'use-query-params';
import {Link} from "react-router-dom";
import {RequestLocationsSearchForm} from "../../../model/RequestLocationsSearchForm";
import useCookie from 'react-use-cookie';
import {mapSource} from "node.glds-react-component-library";

export function LocationSearch(props): JSX.Element {

    const KEY_CODE_ENTER = 13;

    const [source, setSource] = useCookie('source', '');
    const [encodedLocationName, setEncodedLocationName] = useQueryParam('location', withDefault(StringParam, undefined));
    const [locationName, setLocationName] = useState<string | undefined>(undefined);
    const [sourceSelectValue, setSourceSelectValue] = useState<any | null>(null);

    useEffect(() => {
        if (encodedLocationName) {
            setLocationName(decodeURIComponent(encodedLocationName))
        }
    }, [encodedLocationName]);

    useEffect(() => {
        setSourceSelectValue(sourceOptions.find(e => e.value === source));
        if (sourceOptions.length === 1) {
            sourceChangeHandler(sourceOptions[0])
            performSearch(sourceOptions[0].value)
        } else if (source) {
            performSearch();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        performSearch();
    }, [source, locationName]) // eslint-disable-line react-hooks/exhaustive-deps

    const clearSearchCriteria = () => {
        setLocationName(undefined);
        setSource('');
        setSourceSelectValue(null)
    }

    const sourceChangeHandler = (event) => {
        setSourceSelectValue(event);
        setSource(event ? event.value : null)
    }

    const locationNameChangeHandler = (event) => {
        setLocationName(event.target.value)
        setEncodedLocationName(encodeURIComponent(event.target.value))
    }

    const sourceOptions = [
        {value: "1065", label: mapSource("1065")},
        {value: "1067", label: mapSource("1067")},
        {value: "1093", label: mapSource("1093")},
    ];

    const submitHandler = (event) => {
        event.preventDefault();
        performSearch();
    }

    const searchButtonHandler = (event) => {
        performSearch();
    }

    function performSearch(customSource?) {
        let searchRequestData: RequestLocationsSearchForm = {
            source: customSource ?? source,
            locationName: encodedLocationName
        }

        props.onSearch(searchRequestData);
    }

    const keyUpHandler = (e) => {
        if (e.keyCode === KEY_CODE_ENTER) {
            performSearch()
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <Card className="eds-grid eds-grid--m-cols-5 eds-gap--16">
                <div className="searchField">
                    <Select
                        onChange={sourceChangeHandler}
                        value={sourceSelectValue}
                        id="sourceSelect"
                        options={sourceOptions}
                        label="Source"
                        hideLabel={false}
                        placeholder="Source"
                        hasErrors={!source}
                        errorMessage="Please select a source"
                        isClearable={true}
                    />
                </div>
                <div className="searchField">
                    <TextField
                        onChange={locationNameChangeHandler}
                        id="locationSearchField"
                        type="text"
                        value={locationName ?? ""}
                        hasErrors={false}
                        hideLabel={false}
                        placeholder={"Location"}
                        onKeyUp={keyUpHandler}
                        label="Location"
                    />
                </div>
                <div className="eds-grid--m-col-3 eds-grid--item-justify-end">
                    <div className="eds-gap--8 eds-flex eds-flex--direction-row">
                        {source &&
                            <IconButton
                                variant="primary"
                                as={Link}
                                to={source}
                                size={"small"}
                                icon="CreateRule"
                                label={"Create printer"}/>
                        }
                        {!source &&
                            // Link button can not be disabled
                            <Tooltip
                                bodySlot={"Select source first"}
                                enableFocus
                            >
                                <IconButton
                                    variant="primary"
                                    size={"small"}
                                    disabled={!source}
                                    icon="CreateRule"
                                    label={"Create printer"}/>
                            </Tooltip>
                        }
                        <IconButton
                            onClick={searchButtonHandler}
                            variant="primary"
                            disabled={!source}
                            size={"small"}
                            icon="Search"
                            label={"Search"}/>
                        <IconButton
                            onClick={clearSearchCriteria}
                            variant="primary"
                            icon="Undo"
                            size={"small"}
                            label={"Clear search criteria"}/>
                    </div>
                </div>
            </Card>
        </form>
    );

}
