import React, {useContext, useEffect, useState} from "react";
import {FilterableTable, formatDate, SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {useMemo} from "react";
import {Card} from "@nike/eds";

function CupsJobDetailOverviewLogging(props) {

    const [loading, setLoading] = useState(true);
    const cupsJobService = props.cupsJobService;
    const emptyArrayOfEvents: any[] = [];
    const [data, setData] = useState(emptyArrayOfEvents);
    const snackbarCtx = useContext(SnackbarContext);

    const columns = useMemo(() => [
        {
            Header: "Timestamp",
            accessor: "timestamp",
            Cell: (props) => {
                return (
                    <>
                        {formatDate(props.value)}
                    </>
                );
            }
        },
        {
            Header: "Log level",
            accessor: "logLevel"
        },
        {
            Header: "Message",
            accessor: "message"
        },
    ], []);

    useEffect(() => {
        cupsJobService.getLogMessages(props.hashKey).then(result => {
            setLoading(false);
            let logMessages = result.data.getLogMessages.items;
            if (logMessages.length > 0) {
                setData(logMessages);
            } else {
                snackbarCtx.displayMsg("No CUPS logging available for this job. Try refresh to fetch the data again.", SnackbarStatus.neutral);
            }
        }).catch(error => {
            setLoading(false)
            snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Card>
            <FilterableTable data={data} columns={columns} loading={loading}/>
        </Card>
    );
}

export {CupsJobDetailOverviewLogging};