import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import "@nike/eds/dist/index.css";
import {BrowserRouter} from "react-router-dom";
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {SnackBarContextProvider} from "node.glds-react-component-library";

let printerManagementContainer = null;
let root = null;

/////for testing purposes
// let containerId = 'root';
// printerManagementContainer = document.getElementById(containerId);
// root = ReactDOMClient.createRoot(printerManagementContainer);
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </React.StrictMode>
// );
/////

window.renderPrinterManagement = (containerId, history) => {
    if (!printerManagementContainer) {
        printerManagementContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(printerManagementContainer);
        root.render(
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <SnackBarContextProvider>
                        <App history={history}/>
                    </SnackBarContextProvider>
                </QueryParamProvider>
            </BrowserRouter>
        );
        serviceWorker.unregister();
    }
};

window.unmountPrinterManagement = containerId => {
    root.unmount()
    printerManagementContainer = null;
    root = null;
};

if (!document.getElementById('PrinterManagement-container')) {
    //root.render(<App />);
    serviceWorker.unregister();
}