import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    getCupsJob, getLogMessages, getTenantCupsInstanceCupsJobs,
    getTenantCupsJobs, getTenantPrinterCupsJobs
} from "../../graphql/queries";

export class CupsJobService {

    constructor(private appsyncService: AppsyncService) {
    }

    async getTenantCupsJobs(source: string, startDate: Date, endDate: Date, nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getTenantCupsJobs),
                fetchPolicy: 'network-only',
                variables: {
                    tenantCupsJob: "CupsJob-" + source,
                    epochStartTimestampUTC: startDate instanceof Date && !isNaN(startDate.getTime()) ? startDate.getTime() : 0,
                    epochEndTimestampUTC: endDate instanceof Date && !isNaN(endDate.getTime()) ? endDate.getTime() : 9999999999999,
                    limit: 50,
                    nextToken: nextToken,
                }
            }
        );
    }

    async getCupsJob(hashKey: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getCupsJob), fetchPolicy: 'network-only', variables: {hashKey: hashKey}
            }
        )
    }

    async getLogMessages(hashKey: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getLogMessages),
                fetchPolicy: 'network-only',
                variables: {
                    cupsInstance: hashKey.split('-')[0],
                    jobId: hashKey.split('-')[1],
                    count: 500,
                    earliestTime: "-30d"
                }
            }
        );
    }

    async getTenantCupsInstanceCupsJob(source: string, cupsInstance: string, startDate: Date, endDate: Date, nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getTenantCupsInstanceCupsJobs),
                fetchPolicy: 'network-only',
                variables: {
                    tenantCupsInstanceCupsJob: "CupsJob-" + source + "-" + cupsInstance,
                    epochStartTimestampUTC: startDate instanceof Date && !isNaN(startDate.getTime()) ? startDate.getTime() : 0,
                    epochEndTimestampUTC: endDate instanceof Date && !isNaN(endDate.getTime()) ? endDate.getTime() : 9999999999999,
                    nextToken: nextToken,
                }
            }
        );
    }

    async getTenantPrinterCupsJob(source: string, printer: string, startDate: Date, endDate: Date, nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getTenantPrinterCupsJobs),
                fetchPolicy: 'network-only',
                variables: {
                    tenantPrinterCupsJob: "CupsJob-" + source + "-" + printer,
                    epochStartTimestampUTC: startDate instanceof Date && !isNaN(startDate.getTime()) ? startDate.getTime() : 0,
                    epochEndTimestampUTC: endDate instanceof Date && !isNaN(endDate.getTime()) ? endDate.getTime() : 9999999999999,
                    nextToken: nextToken,
                }
            }
        );
    }

}