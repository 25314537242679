import React from "react";
import {Table, TableHeading, TableCell, Skeleton, IconButton, Link as EdsLink} from "@nike/eds";
import {Link} from "react-router-dom";
import {PrinterConfigurationDto} from "../../../generated-sources";
import {URL_LOCATIONS} from "../../shared/Constants";
import useCookie from "react-use-cookie";

function PrinterSearchResults(props) {

    const headers = [
        {Header: "Name"},
        {Header: "Description"},
        {Header: "Physical location"},
        {Header: "Connection"},
        {Header: "Model"},
        {Header: "Trays"},
        {Header: "Locations"},
    ];

    const [source] = useCookie('source', '');

    function PrinterSearchResultItem(props) {
        let searchResult: PrinterConfigurationDto = props.searchResult;
        return (
            <tr>
                <TableCell width={"10%"}>{searchResult.printerName}</TableCell>
                <TableCell width={"15%"}>{searchResult.description}</TableCell>
                <TableCell width={"10%"}>{searchResult.location}</TableCell>
                <TableCell width={"15%"}>{searchResult.connection}</TableCell>
                <TableCell width={"15%"}>{searchResult.makeModel}</TableCell>
                <TableCell width={"15%"}>{searchResult.trays ? searchResult.trays.map(t => t.size).join(", ") : ""}</TableCell>
                <TableCell width={"15%"}>
                    {searchResult.locations ? searchResult.locations.map((location, index, array) => (
                        <EdsLink variant='secondary' as={Link} to={URL_LOCATIONS + "/" + source + "/" + encodeURIComponent(location)}>{location + (index < array.length - 1 ? ',' : '')}</EdsLink>
                    )) : ""}
                </TableCell>
                <TableCell width={"5%"}>
                    <IconButton
                        variant="ghost"
                        as={Link}
                        to={searchResult.node + "/" + encodeURIComponent(searchResult.printerName)}
                        label=""
                        icon="Show"
                    />
                </TableCell>
            </tr>
        );
    }

    return (
        <Table style={{wordBreak: "break-word"}}>
            <thead>
            <tr>
                {headers.map(column => (<TableHeading>{column.Header}</TableHeading>))}
            </tr>
            </thead>
            <tbody>
            {props.loading &&
                <>
                    <tr>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                    </tr>
                </>
            }
            {props.searchResults && props.searchResults.map(searchResult => (
                <PrinterSearchResultItem searchResult={searchResult}/>
            ))}
            </tbody>
        </Table>
    );
}

export {PrinterSearchResults};


