import React, {useState, useContext, useEffect} from "react";
import {LocationConfigurationDto} from "../../../generated-sources";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {PrinterService} from "../../shared/PrinterService";
import {RequestLocationsSearchForm} from "../../../model/RequestLocationsSearchForm";
import {LocationSearchResults} from "./LocationSearchResults";
import {LocationSearch} from "./LocationSearch";

export function LocationSearchOverview(props) {

    let [searchResults, setSearchResults] = useState<LocationConfigurationDto[]>();

    let [selectedSource, setSelectedSource] = useState<string | undefined>();
    let [selectedSourceSearchResults, setSelectedSourceSearchResults] = useState<LocationConfigurationDto[]>();
    let [locationNameFilter, setLocationNameFilter] = useState<string | undefined>();

    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const printerService: PrinterService = props.printerService;

    const searchHandler = async (requestedSearchForm: RequestLocationsSearchForm) => {
        fetchData(requestedSearchForm)
    }

    const fetchData = (requestedSearchForm: RequestLocationsSearchForm) => {
        setLocationNameFilter(requestedSearchForm.locationName);
        if (requestedSearchForm.source) {
            if (selectedSource !== requestedSearchForm.source) {
                setLoading(true);
                printerService.findAllLocations(requestedSearchForm.source)
                    .then(result => {
                        setLoading(false);
                        setSelectedSource(requestedSearchForm.source);
                        setSelectedSourceSearchResults(result.data);
                    })
                    .catch(error => {
                        setLoading(false);
                        snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                    });
            }
        }
    }

    useEffect(() => {
        if (!loading) {
            if (locationNameFilter) {
                let filteredSearchResults = selectedSourceSearchResults?.filter(printer => {
                        return JSON.stringify(printer).toLowerCase().indexOf(locationNameFilter!.toLowerCase()) >= 0
                    }
                );
                setSearchResults(filteredSearchResults);
            } else {
                setSearchResults(selectedSourceSearchResults);
            }
        }
    }, [selectedSourceSearchResults, locationNameFilter]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <LocationSearch onSearch={searchHandler}/>
            <LocationSearchResults searchResults={searchResults} loading={loading}/>
        </div>
    )
}
