/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTenantCupsJobs = /* GraphQL */ `
  query GetTenantCupsJobs(
    $tenantCupsJob: String
    $epochStartTimestampUTC: Long
    $epochEndTimestampUTC: Long
    $limit: Int
    $nextToken: String
  ) {
    getTenantCupsJobs(
      tenantCupsJob: $tenantCupsJob
      epochStartTimestampUTC: $epochStartTimestampUTC
      epochEndTimestampUTC: $epochEndTimestampUTC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        jobId
        printer
        tenant
        cupsInstance
        epochStartTimestampUTC
        epochEndTimestampUTC
        status
        tenantCupsInstanceCupsJob
        tenantCupsJob
        tenantPrinterCupsJob
        epochExpiresAtUTC
      }
      nextToken
    }
  }
`;
export const getCupsJob = /* GraphQL */ `
  query GetCupsJob($hashKey: String!) {
    getCupsJob(hashKey: $hashKey) {
      hashKey
      jobId
      printer
      tenant
      cupsInstance
      epochStartTimestampUTC
      epochEndTimestampUTC
      status
      tenantCupsInstanceCupsJob
      tenantCupsJob
      tenantPrinterCupsJob
      epochExpiresAtUTC
    }
  }
`;
export const getTenantPrinterCupsJobs = /* GraphQL */ `
  query GetTenantPrinterCupsJobs(
    $tenantPrinterCupsJob: String!
    $epochStartTimestampUTC: Long
    $epochEndTimestampUTC: Long
    $limit: Int
    $nextToken: String
  ) {
    getTenantPrinterCupsJobs(
      tenantPrinterCupsJob: $tenantPrinterCupsJob
      epochStartTimestampUTC: $epochStartTimestampUTC
      epochEndTimestampUTC: $epochEndTimestampUTC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        jobId
        printer
        tenant
        cupsInstance
        epochStartTimestampUTC
        epochEndTimestampUTC
        status
        tenantCupsInstanceCupsJob
        tenantCupsJob
        tenantPrinterCupsJob
        epochExpiresAtUTC
      }
      nextToken
    }
  }
`;
export const getTenantCupsInstanceCupsJobs = /* GraphQL */ `
  query GetTenantCupsInstanceCupsJobs(
    $tenantCupsInstanceCupsJob: String!
    $epochStartTimestampUTC: Long
    $epochEndTimestampUTC: Long
    $limit: Int
    $nextToken: String
  ) {
    getTenantCupsInstanceCupsJobs(
      tenantCupsInstanceCupsJob: $tenantCupsInstanceCupsJob
      epochStartTimestampUTC: $epochStartTimestampUTC
      epochEndTimestampUTC: $epochEndTimestampUTC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        jobId
        printer
        tenant
        cupsInstance
        epochStartTimestampUTC
        epochEndTimestampUTC
        status
        tenantCupsInstanceCupsJob
        tenantCupsJob
        tenantPrinterCupsJob
        epochExpiresAtUTC
      }
      nextToken
    }
  }
`;
export const getLogMessages = /* GraphQL */ `
  query GetLogMessages(
    $cupsInstance: String!
    $jobId: String!
    $count: String
    $earliestTime: String
  ) {
    getLogMessages(
      cupsInstance: $cupsInstance
      jobId: $jobId
      count: $count
      earliestTime: $earliestTime
    ) {
      items {
        timestamp
        logLevel
        message
        componentName
        stackTrace
      }
    }
  }
`;
