import {OktaAuth} from "@okta/okta-auth-js";
import {Configuration, ConfigurationParameters, DefaultApi, LocationConfigurationDto, PrinterConfigurationDto, PrinterModelsInner} from "../../generated-sources";
import type {AxiosPromise, AxiosRequestConfig} from 'axios';

export class PrinterService {

    private apiInstance: DefaultApi;
    private options: AxiosRequestConfig;

    constructor(private oktaAuth: OktaAuth) {
        const {REACT_APP_PRINTER_MANAGEMENT_ENDPOINT: apiEndpoint} = process.env

        function resolveToken() {
            let token = oktaAuth.getAccessToken();
            if (!token) {
                throw new Error("Could not retrieve token");
            }
            return token;
        };

        let configuration: ConfigurationParameters = {
            basePath: apiEndpoint
        }

        this.options = {
            headers: {"Authorization": "Bearer " + resolveToken()},
        };

        this.apiInstance = new DefaultApi(new Configuration(configuration));
    }


    findAllPrinters(source: string): AxiosPromise<Array<PrinterConfigurationDto>> {
        return this.apiInstance.findAllPrinters(source, this.options);
    }

    findPrinter(node: string, name: string): AxiosPromise<PrinterConfigurationDto> {
        return this.apiInstance.findByPrinter(node, name, this.options);
    }

    savePrinter(printer: PrinterConfigurationDto): AxiosPromise<void> {
        return this.apiInstance.updatePrinter(printer, this.options);
    }

    createPrinter(printer: PrinterConfigurationDto): AxiosPromise<void> {
        return this.apiInstance.createPrinter(printer, this.options);
    }

    findAllLocations(node: string): AxiosPromise<Array<LocationConfigurationDto>> {
        return this.apiInstance.findAllLocations(node, this.options);
    }

    findLocation(node: string, location: string): AxiosPromise<LocationConfigurationDto> {
        return this.apiInstance.findByLocation(node, location, this.options);
    }

    saveLocation(location: LocationConfigurationDto): AxiosPromise<void> {
        return this.apiInstance.updateLocation(location, this.options);
    }

    createLocation(location: LocationConfigurationDto): AxiosPromise<void> {
        return this.apiInstance.createLocation(location, this.options);
    }

    deletePrinter(node: string, name: string): AxiosPromise<void> {
        return this.apiInstance.deleteByPrinter(node, name, this.options);
    }

    deleteLocation(node: string, name: string): AxiosPromise<void> {
        return this.apiInstance.deleteLocation(node, name, this.options);
    }

    findAllPrinterModels(): AxiosPromise<Array<PrinterModelsInner>> {
        return this.apiInstance.getPrinterModel(this.options);
    }

}