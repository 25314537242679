import React from "react";
import {useParams} from 'react-router-dom'
import {CupsJobDetailHeader} from "./CupsJobDetailHeader";
import {CupsJobDetailOverviewLogging} from "./CupsJobDetailOverviewLogging";

export function CupsJobDetail(props) {
    const params = useParams();
    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <CupsJobDetailHeader
                hashKey={params.hashKey} cupsJobService={props.cupsJobService}/>
            <CupsJobDetailOverviewLogging
                hashKey={params.hashKey} cupsJobService={props.cupsJobService}/>
        </div>
    );
}

