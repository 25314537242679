import React, {useEffect} from "react";
import {useState} from "react";
import {Card} from "@nike/eds";
import {TextField} from "@nike/eds";
import {Button} from "@nike/eds";
import {IconButton} from "@nike/eds";
import {Select} from "@nike/eds";
import {RequestCupsJobsSearchForm} from "../../../model/RequestCupsJobsSearchForm";
import {useQueryParam, StringParam, BooleanParam, withDefault} from 'use-query-params';
import useCookie from "react-use-cookie";
import {mapSource} from "node.glds-react-component-library";

export function CupsJobSearch(props): JSX.Element {

    const [advancedSearch, setAdvancedSearch] = useQueryParam('advanced', withDefault(BooleanParam, false));
    const [source, setSource] = useCookie('source', '');
    const [printer, setPrinter] = useQueryParam('printer', withDefault(StringParam, undefined));
    const [cupsInstance, setCupsInstance] = useQueryParam('cupsInstance', withDefault(StringParam, undefined));
    const [startDate, setStartDate] = useQueryParam('startDate', withDefault(StringParam, undefined));
    const [endDate, setEndDate] = useQueryParam('endDate', withDefault(StringParam, undefined));

    const [sourceSelectValue, setSourceSelectValue] = useState<any | null>(null);
    const [endDateValidationErrorMessage, setEndDateValidationErrorMessage] = useState('');
    const [startDateValidationErrorMessage, setStartDateValidationErrorMessage] = useState('');

    useEffect(() => {
        setSourceSelectValue(sourceOptions.find(e => e.value === source));
        if (sourceOptions.length === 1) {
            sourceChangeHandler(sourceOptions[0])
            performSearch(sourceOptions[0].value)
        } else if (source) {
            performSearch();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        validateSearchCriteriaDates()
    }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const clearSearchCriteria = () => {
        setPrinter(undefined);
        setSource('');
        setSourceSelectValue(null)
        setCupsInstance(undefined);
        setStartDate(undefined);
        setEndDate(undefined);
    }

    const toggleSimpleAdvancedSearch = () => {
        setAdvancedSearch(!advancedSearch)
    }
    const sourceChangeHandler = (event) => {
        setSourceSelectValue(event);
        setSource(event.value)
    }
    const printerChangeHandler = (event) => {
        setPrinter(event.target.value)
    }
    const cupsInstanceChangeHandler = (event) => {
        setCupsInstance(event.target.value)
    }
    const startDateChangeHandler = (event) => {
        setStartDate(event.target.value)
    }
    const endDateChangeHandler = (event) => {
        setEndDate(event.target.value)
    }
    const sourceOptions = [
        {value: "1065", label: mapSource("1065")},
        {value: "1067", label: mapSource("1067")},
        {value: "1093", label: mapSource("1093")},
    ];

    const submitHandler = (event) => {
        event.preventDefault();
        performSearch();
    }

    function performSearch(customSource?) {
        let searchRequestData: RequestCupsJobsSearchForm = {
            source: customSource ?? source,
            printer: printer
        }

        if (advancedSearch) {
            searchRequestData = {
                ...searchRequestData,
                cupsInstance: cupsInstance,
                startDate: new Date(startDate ?? ""),
                endDate: new Date(endDate ?? "")
            }
        }
        props.onSearch(searchRequestData);
    }

    function validateSearchCriteriaDates(): boolean {
        setStartDateValidationErrorMessage('');
        setEndDateValidationErrorMessage('');
        if (!!startDate && !!endDate && (startDate > endDate)) {
            setStartDateValidationErrorMessage("The start date should be before the end date");
            setEndDateValidationErrorMessage("The end date should be after the start date");
            return false;
        }
        return true;
    }

    return (
        <form onSubmit={submitHandler}>
            <Card className="eds-grid eds-grid--m-cols-5 eds-gap--16">
                <div className="searchField eds-grid--col-start-1 eds-grid--row-start-1">
                    <Select
                        onChange={sourceChangeHandler}
                        id="sourceSelect"
                        value={sourceSelectValue}
                        options={sourceOptions}
                        label="Source"
                        placeholder={advancedSearch ? "" : "Source"}
                        hasErrors={!source}
                        errorMessage="Please select a source"
                        isClearable={true}
                    />
                </div>
                <div className="searchField eds-grid--col-start-2 eds-grid--row-start-1">
                    <TextField
                        onChange={printerChangeHandler}
                        id="printerSearchField"
                        type="text"
                        value={printer ?? ""}
                        hasErrors={false}
                        placeholder="Printer"
                        label="Printer"
                        disabled={!!cupsInstance}
                    />
                </div>
                <div className="searchField eds-grid--col-start-2 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <TextField onChange={cupsInstanceChangeHandler}
                               id="cupsInstanceSearchField"
                               type="text"
                               hasErrors={false}
                               label="Cups instance"
                               value={cupsInstance}
                               placeholder="Cups instance"
                               disabled={!!printer}
                    />
                </div>
                <div className="searchField eds-grid--col-start-3 eds-grid--row-start-1" hidden={!advancedSearch}>
                    <TextField
                        onChange={startDateChangeHandler}
                        value={startDate ?? ""}
                        id="id"
                        type="datetime-local"
                        label="Enter a start date"
                        hasErrors={!!startDateValidationErrorMessage}
                        errorMessage={startDateValidationErrorMessage}
                    />
                </div>
                <div className="searchField eds-grid--col-start-3 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <TextField
                        onChange={endDateChangeHandler}
                        value={endDate ?? ""}
                        id="id"
                        type="datetime-local"
                        label="Enter an end date"
                        hasErrors={!!endDateValidationErrorMessage}
                        errorMessage={endDateValidationErrorMessage}
                    />
                </div>
                <div className="eds-grid--col-start-4 eds-grid--row-start-1 eds-grid--col-end-6 eds-grid--item-justify-end">
                    <div className="eds-gap--8 eds-flex eds-flex--direction-row">
                        <div>
                            <IconButton
                                variant="primary"
                                disabled={!source || new Date(startDate ?? "") > new Date(endDate ?? "")}
                                type={"submit"}
                                size={"small"}
                                icon="Search"
                                label={""}/>
                        </div>
                        <div>
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Undo"
                                size={"small"}
                                label={""}/>
                        </div>
                        <div>
                            <Button
                                onClick={toggleSimpleAdvancedSearch}
                                variant="primary"
                                size={"small"}
                            >{!advancedSearch ? 'Advanced Search' : 'Simple Search'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </form>
    );
}
