import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Button, ButtonGroup, Card, Icon, Modal, Select, TextField} from "@nike/eds";
import {PrinterService} from "../../shared/PrinterService";

import {PrinterConfigurationDto, PrinterModelsInner, TrayDto} from "../../../generated-sources";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {URL_PRINTERS} from "../../shared/Constants";
import {isValidConnectionString, isValidPrinterName} from "../../../util/utils";

export function PrinterDetail(props) {

    type OptionType = {
        value: string;
        label: string;
    };

    const snackbarCtx = useContext(SnackbarContext);
    const params = useParams();
    const navigate = useNavigate();
    const printerService: PrinterService = props.printerService;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const node: string = params.node!;
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string | undefined>("");
    const [connection, setConnection] = useState<string | undefined>("");
    const [location, setLocation] = useState<string | undefined>("");
    const [model, setModel] = useState<OptionType>();
    const [locations, setLocations] = useState<OptionType[]>();
    const [sizes, setSizes] = useState<OptionType[]>();
    const [size, setSize] = useState<OptionType>();
    const [isMultiTray, setIsMultiTray] = useState<Boolean>();

    const [modelsOptions, setModelsOptions] = useState<OptionType[]>();
    const [locationsOptions, setLocationsOptions] = useState<OptionType[]>();
    const [sizesOptions, setSizesOptions] = useState<OptionType[]>();

    const [allPrinterModels, setAllPrinterModels] = useState<undefined | PrinterModelsInner[]>();

    useEffect(() => {
        printerService.findAllLocations(node)
            .then(locations => {
                    setLocationsOptions(locations.data.map(location => {
                        return {value: location.locationName, label: location.locationName};
                    }))
                }
            );

        printerService.findAllPrinterModels()
            .then(printerModels => {
                    setAllPrinterModels(printerModels.data);
                    setModelsOptions(printerModels.data.map(printer => {
                        return {value: printer.modelName ?? "", label: printer.modelName ?? ""};
                    }))
                }
            );

        if (params.printerName) {
            printerService.findPrinter(node, decodeURIComponent(params.printerName)).then(
                printer => {
                    setName(printer.data.printerName)
                    setDescription(printer.data.description)
                    setLocation(printer.data.location)
                    if (printer.data.makeModel) {
                        setModel({value: printer.data.makeModel, label: printer.data.makeModel});
                    }
                    setConnection(printer.data.connection)
                    setSizes(printer.data.trays?.map(tray => {
                        return {value: tray.size ?? "", label: tray.size ?? ""}
                    }))
                    let tray: TrayDto | undefined = printer.data.trays?.[0];
                    if (tray) {
                        setSize({value: tray.size ?? "", label: tray.size ?? ""});
                    }
                    setLocations(printer.data.locations?.map(location => {
                        return {value: location, label: location}
                    }))
                }
            ).catch(
                error => {
                    if (error.response.status === 404) {
                        snackbarCtx.displayMsg("Printer with name " + params.printerName + " not found.", SnackbarStatus.error);
                        navigate("/printer-management/printers/" + params.node + "/");
                    }
                }
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (allPrinterModels && model) {
            let selectedModel: PrinterModelsInner | undefined = allPrinterModels.find(i => i.modelName === model.value);
            if (selectedModel) {
                setIsMultiTray(selectedModel.multiTray)
                if (selectedModel.sizes) {
                    let newSizesOptions: OptionType[] = selectedModel.sizes.map(size => {
                        return {value: size, label: size}
                    });
                    setSizesOptions(newSizesOptions);
                }
            }
        }
    }, [ model, allPrinterModels]);

    const modelChangeHandler = (event) => {
        let newModel = event;
        setModel(newModel);
        if (newModel && allPrinterModels) {
            let selectedModel: PrinterModelsInner | undefined = allPrinterModels.find(i => i.modelName === newModel.value);
            if (selectedModel) {
                setIsMultiTray(selectedModel.multiTray)
                if (selectedModel.prefix) {
                    setConnection(selectedModel.prefix);
                }
                if (selectedModel.sizes) {
                    let newSizesOptions: OptionType[] = selectedModel.sizes.map(size => {
                        return {value: size, label: size}
                    });
                    if (sizes && selectedModel.multiTray) {
                        setSizes(sizes.filter(size => newSizesOptions.findIndex(obj => obj.value === size.value) >= 0));
                        setSize(undefined)
                    }
                    if (size && !selectedModel.multiTray) {
                        setSize(newSizesOptions.findIndex(obj => obj.value === size.value) >= 0 ? size : undefined);
                        setSizes(undefined)
                    }
                    setSizesOptions(newSizesOptions);
                }
            }
        }
    }

    const sizesChangeHandler = (event) => {
        setSizes(event);
    }

    const sizeChangeHandler = (event) => {
        setSize(event);
    }

    const locationChangeHandler = (event) => {
        setLocations(event);
    }

    function calculateSize(): Array<TrayDto> {
        if (isMultiTray) {
            return (sizes ? sizes.map(s => {
                return {size: s.value}
            }) : []);
        } else if (size) {
            return Array.of({size: size.value});
        }
        return [];
    }

    function isFormValid(): boolean {
        if (!name || !connection || !model || !isValidConnectionString(connection) || !isValidPrinterName(name)) {
            return false;
        }
        return true;
    }

    const createOrUpdatePrinter = (e) => {
        if (!isFormValid()) {
            snackbarCtx.displayMsg("Complete all fields", SnackbarStatus.error);
            return;
        }

        let printer: PrinterConfigurationDto = {
            node: node,
            printerName: params.printerName ?? name,
            description: description,
            connection: connection,
            location: location,
            makeModel: model ? model.value : undefined,
            trays: calculateSize(),
            locations: locations ? locations.map(location => location.value) : []
        }

        if (params.printerName) {
            printerService.savePrinter(printer)
                .then(r => {
                    console.log(r.status);
                    snackbarCtx.displayMsg("Printer successfully saved", SnackbarStatus.success);
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.response.data.message, SnackbarStatus.error);
                });
        } else {
            printerService.createPrinter(printer)
                .then(r => {
                    snackbarCtx.displayMsg("Printer successfully created", SnackbarStatus.success);
                    navigate(encodeURIComponent(printer.printerName))
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.response.data.message, SnackbarStatus.error);
                });
        }
    }

    const showDeleteConfirmationModal = (e) => {
        e.preventDefault();
        setShowDeleteConfirmation(true);
    }

    const deletePrinter = () => {
        if (params.printerName) {
            printerService.deletePrinter(node, params.printerName)
                .then(r => {
                    setShowDeleteConfirmation(false);
                    navigate(URL_PRINTERS);
                    snackbarCtx.displayMsg("Printer successfully deleted", SnackbarStatus.success);
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                });
        }
    }

    return (
        <form id="printer-detail">
            <Card className="eds-flex eds-flex--direction-column eds-gap--16">
                <Button className="eds-flex--align-self-flex-start"
                        onClick={() => navigate(URL_PRINTERS)}
                        variant="primary"
                        size={"small"}>
                    <Icon name="CaretLeft" backgroundShape="square"/>
                    Back to overview
                </Button>
                <TextField
                    id="name"
                    label="Name"
                    placeholder="Name of the printer"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    readOnly={!!params.printerName}
                    hasErrors={!name || !isValidPrinterName(name)}
                    errorMessage={name ? "Printer name should not exceed 50 chars" : "Name is required"}
                />
                <TextField
                    id="description"
                    label="Description"
                    placeholder="Description of the printer"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
                <TextField
                    id="location"
                    label="Physical location"
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                />
                <Select
                    id="model"
                    label="Model"
                    value={model}
                    options={modelsOptions}
                    onChange={modelChangeHandler}
                    hasErrors={!model}
                    errorMessage="Model is required"
                />
                <TextField
                    id="connection"
                    label="Connection"
                    value={connection}
                    onChange={e => setConnection(e.target.value)}
                    hasErrors={!connection || !isValidConnectionString(connection)}
                    errorMessage='Connection is required and needs to be a valid connection string ("<protocol>://<ip>" or "<protocol>://<printername>.nike.com")'
                />
                {isMultiTray &&
                    <Select
                        id="trays"
                        label="Tray"
                        isMulti
                        value={sizes}
                        options={sizesOptions}
                        onChange={sizesChangeHandler}
                    />
                }
                {!isMultiTray &&
                    <Select
                        id="trays"
                        label="Tray"
                        value={size}
                        options={sizesOptions}
                        onChange={sizeChangeHandler}
                    />
                }
                <Select
                    id="locations"
                    label="Locations"
                    isMulti
                    value={locations}
                    options={locationsOptions}
                    onChange={locationChangeHandler}
                />
                <div
                    className="eds-flex eds-flex--direction-row eds-gap--16">
                    { // Create or update always visible
                        <Button className="eds-button eds-button--primary"
                                onClick={createOrUpdatePrinter}
                                variant="primary"
                                size={"small"}
                                disabled={!isFormValid()}
                        >
                            <Icon name="CheckCircle" backgroundShape="square"/>
                            {params.printerName ? "Save printer" : "Create printer"}
                        </Button>
                    }
                    {params.printerName &&
                        <Button
                            onClick={(e) => showDeleteConfirmationModal(e)}
                            variant="primary"
                            size={"small"}
                        >
                            <Icon name="Delete" backgroundShape="square"/>
                            Delete printer
                        </Button>
                    }
                </div>
            </Card>
            <Modal
                onDismiss={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
                isOpen={showDeleteConfirmation}
                headerSlot={"Delete printer"}
                footerSlot={
                    <ButtonGroup>
                        <Button onClick={deletePrinter} size="small">Delete</Button>
                        <Button
                            onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
                            size="small"
                            variant="secondary"
                        >Cancel</Button>
                    </ButtonGroup>
                }
            >
                <p className="eds-type--body-1"> Are you sure you want to delete the printer? </p>
            </Modal>
        </form>
    );
}
