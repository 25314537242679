import {Label} from "@nike/eds";
import React from "react";

export function PropertyValue(props) {
    return (
        <div className={"eds-grid--m-col-1 " + (props.className ?? "")}>
            <Label font="title-6" className="eds-spacing--p-8">
                {props.label}
            </Label>
            <Label font="body-3" className="eds-spacing--p-8">
                {props.value}
            </Label>
        </div>
    );
}