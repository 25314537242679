import React, {useState, useContext, useEffect} from "react";
import {PrinterSearch} from "./PrinterSearch";
import {PrinterSearchResults} from "./PrinterSearchResults";
import {RequestPrintersSearchForm} from "../../../model/RequestPrintersSearchForm";
import {PrinterService} from "../../shared/PrinterService";
import {PrinterConfigurationDto} from "../../../generated-sources";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";

export function PrinterSearchOverview(props) {

    let [searchResults, setSearchResults] = useState<PrinterConfigurationDto[]>();

    let [selectedSource, setSelectedSource] = useState<string | undefined>();
    let [selectedSourceSearchResults, setSelectedSourceSearchResults] = useState<PrinterConfigurationDto[]>();
    let [printerNameFilter, setPrinterNameFilter] = useState<string | undefined>();

    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const printerService: PrinterService = props.printerService;

    const searchHandler = async (requestedSearchForm: RequestPrintersSearchForm) => {
        fetchData(requestedSearchForm)
    }

    const fetchData = (requestedSearchForm: RequestPrintersSearchForm) => {
        setPrinterNameFilter(requestedSearchForm.printerName);
        if (requestedSearchForm.source) {
            if (selectedSource !== requestedSearchForm.source) {
                setLoading(true);
                printerService.findAllPrinters(requestedSearchForm.source)
                    .then(result => {
                        setLoading(false);
                        setSelectedSource(requestedSearchForm.source);
                        setSelectedSourceSearchResults(result.data);
                    })
                    .catch(error => {
                        setLoading(false);
                        snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                    });
            }
        }
    }

    useEffect(() => {
        if (!loading) {
            if (printerNameFilter) {
                let filteredSearchResults = selectedSourceSearchResults?.filter(printer => {
                        return JSON.stringify(printer).toLowerCase().indexOf(printerNameFilter!.toLowerCase()) >= 0
                    }
                );
                setSearchResults(filteredSearchResults);
            } else {
                setSearchResults(selectedSourceSearchResults);
            }
        }
    }, [selectedSourceSearchResults, printerNameFilter]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <PrinterSearch onSearch={searchHandler}/>
            <PrinterSearchResults searchResults={searchResults} loading={loading}/>
        </div>
    )
}
