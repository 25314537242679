import React, {useState, useContext} from "react";
import {CupsJobSearch} from "./CupsJobSearch";
import {CupsJobSearchResults} from "./CupsJobSearchResults";
import {RequestCupsJobsSearchForm} from "../../../model/RequestCupsJobsSearchForm";
import {Button} from "@nike/eds";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";

export function CupsJobSearchOverview(props) {

    let [searchResults, setSearchResults] = useState([]);
    const [currentSearchForm, setCurrentSearchForm] = useState({})
    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const cupsJobService = props.cupsJobService;
    let [nextToken, setNextToken] = useState('')

    const searchHandler = async (requestedSearchForm: RequestCupsJobsSearchForm) => {
        setCurrentSearchForm(requestedSearchForm);
        fetchData(requestedSearchForm, '', false)
    }

    const fetchData = (requestedSearchForm: RequestCupsJobsSearchForm, nextToken: String, concat: Boolean) => {
        // All selections are source/tenant dependent
        setLoading(true);
        if (requestedSearchForm.cupsInstance && requestedSearchForm.jobId) {
            cupsJobService.getCupsJob(requestedSearchForm.cupsInstance + "-" + requestedSearchForm.jobId)
                .then(result => {
                    setLoading(false);
                    setSearchResults(result.data.getCupsJob.items)
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                });
        } else if (requestedSearchForm.printer) {
            cupsJobService.getTenantPrinterCupsJob(requestedSearchForm.source, requestedSearchForm.printer, requestedSearchForm.startDate, requestedSearchForm.endDate, nextToken)
                .then(result => {
                    setLoading(false);
                    setSearchResults(concat ? searchResults.concat(result.data.getTenantPrinterCupsJobs.items) : result.data.getTenantPrinterCupsJobs.items)
                    setNextToken(result.data.getTenantPrinterCupsJobs.nextToken)
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                });
        } else if (requestedSearchForm.cupsInstance) {
            cupsJobService.getTenantCupsInstanceCupsJob(requestedSearchForm.source, requestedSearchForm.cupsInstance, requestedSearchForm.startDate, requestedSearchForm.endDate, nextToken)
                .then(result => {
                    setLoading(false);
                    setSearchResults(concat ? searchResults.concat(result.data.getTenantCupsInstanceCupsJobs.items) : result.data.getTenantCupsInstanceCupsJobs.items)
                    setNextToken(result.data.getTenantCupsInstanceCupsJobs.nextToken)
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                });
        } else if (requestedSearchForm.source) {
            cupsJobService.getTenantCupsJobs(requestedSearchForm.source, requestedSearchForm.startDate, requestedSearchForm.endDate, nextToken)
                .then(result => {
                    setLoading(false);
                    setSearchResults(concat ? searchResults.concat(result.data.getTenantCupsJobs.items) : result.data.getTenantCupsJobs.items)
                    setNextToken(result.data.getTenantCupsJobs.nextToken)
                })
                .catch(error => {
                    setLoading(false);
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                });
        }
    }

    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <CupsJobSearch onSearch={searchHandler}/>
            <CupsJobSearchResults searchResults={searchResults} loading={loading}/>
            <div>
                {nextToken ?
                    <Button
                        onClick={() => fetchData(currentSearchForm, nextToken, true)}
                        variant="primary"
                        size={"small"}
                    >Load more
                    </Button>
                    : ""}
            </div>
        </div>
    )
}
