const MAX_LEN_PRINTERNAME = 50;
const MAX_LEN_LOCATIONNAME = 15;

// Regex for connection string: <protocol>://<hostname.nike.com|ip-address>:<port>/<path> -> port and path are optional
const REGEX_IP_ADDRESS = "((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
const REGEX_CONNECTION_STRING = "^((cups-pdf:\\/)|(((socket)|(http)|(lpd)|(ipp))):\\/\\/(("+ REGEX_IP_ADDRESS +")|([a-zA-Z0-9-.]+\\.nike\\.com))(:[0-9]+)?(\\/[a-zA-Z0-9]*)*)$";

export function isValidConnectionString(connection: string) {
    const validConnection = new RegExp(
        REGEX_CONNECTION_STRING
    );
    return validConnection.test(connection)
}

export function isValidPrinterName(printerName: string) {
    return printerName.length <= MAX_LEN_PRINTERNAME;
}

export function isValidLocationName(locationName: string) {
    return locationName.length <= MAX_LEN_LOCATIONNAME;
}
